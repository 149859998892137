$(document).ready(function(){
	/*
	$(".page-content .news .list").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		centerMode: false,
		autoplay: false,
		infinite: false,
		draggable: true,
		focusOnSelect: true
	});
	$('.page-content .news .list').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		if( currentSlide != nextSlide ){
			var index = nextSlide;
			
			var item = $('.slider-nav-container div.item');
			var currentItem = $('.slider-nav-container div.item').eq(index);
			var box = $('.slider-nav-container .wrapper');
			
			item.each(function(){
				$(this).removeClass('active');
			});
			
			box.animate({scrollLeft: box.scrollLeft() + (currentItem.offset().left - box.offset().left) - 26});
			currentItem.addClass('active');
		}
	});
	$('.page-content .news .next').on('click', function(){
		$('.page-content .news .list').slick('slickNext');
	});
	$('.page-content .news .prev').on('click', function(){
		$('.page-content .news .list').slick('slickPrev');
	});
	
	if($('.page-content .news .list').length > 0 && $('.temp').length > 0){
		sliceNews();
	}
	*/
	
	$(document).on('click', '#news .tabs ul li a', function(e){
		e.preventDefault();
		var sectionId = $(this).parent().data('section');
		
		$('#news .tabs ul li a').each(function(){
			$(this).removeClass('active');
		});
		$(this).addClass('active');
		
		$.ajax({
			url: '/ajax/news_main.php',
			data: 'SECTION_ID='+sectionId,
			method: 'POST',
			cache: false,
			success: function(response)
			{
				$('section#news .slider').html(response);
				$('section#news .slider').slick('refresh');
				
				$('section#news .slider-nav').html('');
				for (var i = 1; i <= $('section#news .slick-slide').length; i++) {
					if(i == 1){
						$('section#news .slider-nav').append('<div class="item active">'+i+'</div>');
					}else{
						$('section#news .slider-nav').append('<div class="item">'+i+'</div>');
					}
				}
			}
		});
	});
});

function sliceNews(){
	$('.temp').html();
	
	$(".page-content .news .list .item").each(function(){
		var cl = $(this).clone();
		$(cl).appendTo('.temp');
	});
	
	$(".temp").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		arrows: false,
		centerMode: false,
		autoplay: false,
		infinite: false,
		draggable: true,
		focusOnSelect: true,
		adaptiveHeight: true
	});
}