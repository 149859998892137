$(document).ready(function(){
	slider_gallery();
	$('.gallery .list').magnificPopup({
		delegate: 'a',
		type: 'image',
		removalDelay: 500, //delay removal by X to allow out-animation
		gallery:{
			enabled: true
		},
		callbacks: {
			beforeOpen: function() {
				// just a hack that adds mfp-anim class to markup
				this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
				this.st.mainClass = this.st.el.attr('data-effect');
			}
		},
		showCloseBtn: false,
		closeOnContentClick: true,
		midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
	});
});

$(window).resize(function () {
	slider_gallery();
});

function slider_gallery(){
	if($('.gallery .list').length > 0){
		if( $('.gallery .list').hasClass('slick-initialized') ) {
			$('.gallery .list').slick('unslick');
		}
		$('.gallery .list').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: false,
			centerMode: false,
			autoplay: false,
			infinite: false,
			draggable: true,
			focusOnSelect: false,
			mobileFirst: true,
			adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 525,
					settings: "unslick"
				},
			]
		});
	}
}