$(document).ready(function(){
	$("#reviews-text .list").slick({
		slidesToShow: 3,
		slidesToScroll: 3,
		dots: false,
		arrows: false,
		centerMode: false,
		autoplay: false,
		infinite: false,
		draggable: true,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 980,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					dots: true
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true
				}
			},
		]
	});
	$('#reviews-text .list').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		if( currentSlide != nextSlide && !checkWindowWidth(980)){
			var index = $( ".slider-nav-container .active" ).index();
			var item = $('.slider-nav-container div.item');
			
			if(nextSlide > currentSlide){
				index++;
			}else{
				index--;
			}
			var box = $('.slider-nav-container .wrapper');
			var currentItem = $('.slider-nav-container div.item').eq(index);

			item.each(function(){
				$(this).removeClass('active');
			});
			currentItem.addClass('active');

			box.animate({scrollLeft: box.scrollLeft() + (currentItem.offset().left - box.offset().left) - 26});
			currentItem.addClass('active');
		}
	});
	$('#reviews-text .next').on('click', function(){
		$('#reviews-text .list').slick('slickNext');
	});
	$('#reviews-text .prev').on('click', function(){
		$('#reviews-text .list').slick('slickPrev');
	});
	
	//main page
	
});