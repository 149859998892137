$(document).ready(function() {
	$(".media-list .slider-nav").slick({
		slidesToShow: 5,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		centerMode: false,
		autoplay: false,
		infinite: false,
		draggable: true,
		focusOnSelect: false,
		responsive: [
			{
				breakpoint: 980,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 525,
				settings: {
					slidesToShow: 3,
				}
			},
		]
	});
	$('.media-list .next').on('click', function(){
		$('.media-list .slider-nav').slick('slickNext');
	});
	$('.media-list .prev').on('click', function(){
		$('.media-list .slider-nav').slick('slickPrev');
	});
	
	$(document).on('click', '.media-list .slider-nav a', function (e) {
		e.preventDefault();
		slider_set_content($(this).html());
		
		$('.media-list .slider-nav a').each(function(){
			$(this).removeClass('active');
		});
		$(this).addClass('active');
	});
	
	//media slider
	$('#media .year-slider .wrapper .list').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: 0,
		dots: false,
		arrows: false,
		infinite: true,
		autoplay: false,
		draggable: true,
		focusOnSelect: false,
		vertical: true
	});
	$('#media .next').on('click', function(){
		$('#media .year-slider .wrapper .list').slick('slickNext');
	});
	$('#media .prev').on('click', function(){
		$('#media .year-slider .wrapper .list').slick('slickPrev');
	});
	$('#media .year-slider .wrapper .list').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		if( currentSlide != nextSlide ){

			var index = nextSlide;
			var currentItem = $('#media .year-slider .wrapper .list div.item[data-slick-index='+index+']');
			
			var year = currentItem.find('span').html();
			
			grid_set_content(year);
		}
	});
	
	slider_set_content();
	grid_set_content();
	
	$(document).on('click', '#media .grid a', function(e){
		e.preventDefault();
		
		var id = $(this).data('id');

		$.ajax({
			url: '/ajax/get_media_item.php',
			data: "ID="+id,
			method: 'POST',
			cache: true,
			success: function(response)
			{
				var items = [];
				var data = $.parseJSON(response);
				data.forEach(function(item, i, arr){
					items.push({
						src: item
					});
				});
				
				$.magnificPopup.open({
					items: items,
					type: 'image',
					gallery:{
						enabled: true
					},
					callbacks: {
						updateStatus: function(){
							var mfp = jQuery.magnificPopup.instance;
							$('.mfp-img').click(function() {
								$.magnificPopup.close();
							});
						},
					}
				}, 0);
			}
		});
	});
	
	/*
	$('#media .grid').magnificPopup({
		delegate: 'a',
		type: 'image',
		removalDelay: 500, //delay removal by X to allow out-animation
		callbacks: {
			beforeOpen: function() {
				// just a hack that adds mfp-anim class to markup
				this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
				this.st.mainClass = this.st.el.attr('data-effect');
			}
		},
		showCloseBtn: false,
		closeOnContentClick: true,
		midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
	});
	*/
});

$(window).resize(function () {
	slider_init($(".media-list .list"));
});

function slider_set_content(year = false){
	if($('.media-list .list').length > 0){
		if(!year){
			var year = $('.slider-nav .slick-slide').first().find('a').html();
			$(".slider-nav .slick-slide a:contains('"+year+"')").addClass('active');
		}
		$.ajax({
			url: '/ajax/media_list.php',
			data: 'YEAR='+year,
			method: 'POST',
			cache: false,
			success: function(response)
			{
				$('.media-list .list').html(response);
				if( $('.media-list .list').hasClass('slick-initialized') ) {
					$('.media-list .list').slick('refresh');
				}else{
					slider_init($(".media-list .list"));
				}
			}
		});
	}
}
function grid_set_content(year = false){
	if($('#media .grid').length > 0){
		if(!year){
			var year = $('#media .year-slider .slick-current').find('span').html();
		}
		
		$.ajax({
			url: '/ajax/media_grid.php',
			data: 'YEAR='+year,
			method: 'POST',
			cache: false,
			success: function(response)
			{
				var data = $.parseJSON(response);
				
				$('#media .grid').html(data.content);
				if(data.title.length > 0){
					//$('#media .info .title').html(data.title);
				}
				
				if(window['ps'] != null){
					window['ps'].update();
				}
			}
		});
	}
}

function slider_init(node){
	if(node.length > 0){
		if( node.hasClass('slick-initialized') ) {
			node.slick('unslick');
		}
		node.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: false,
			centerMode: false,
			autoplay: false,
			infinite: false,
			draggable: true,
			focusOnSelect: false,
			mobileFirst: true,
			adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 768,
					settings: "unslick"
				},
			]
		});
	}
}