//media scrollbar
if(checkWindowWidth(767) == false && $('#media').length > 0){
	window['ps'] = new PerfectScrollbar('#media > .content .wrapper', {
		wheelSpeed: 2,
		wheelPropagation: true,
		minScrollbarLength: 20,
		maxScrollbarLength: 75,
		suppressScrollY: true,
	});
}

if($('section#info').length > 0){
	var positions = new Object();
	var scrolling = false;
	var lastScrollTop = document.documentElement.scrollTop ? document.documentElement.scrollTop : 0;

	$('section').each(function(){
		positions[$(this).attr('id')] = $(this).offset().top;
		if($(this).attr('id') !== 'about'){
			//positions[$(this).attr('id')] = $(this).offset().top;
		}
	});

	$(window).scroll(function(event){

		if(scrolling == true){
			event.preventDefault();
			return;
		}

		var st = window.pageYOffset || document.documentElement.scrollTop;

		if (st > lastScrollTop){
			for (const [key, value] of Object.entries(positions)) {
				if(st < value){
					scrolling = true;
					$([document.documentElement, document.body]).animate(
						{
							scrollTop: value
						},
						3000,
						$.bez([0.8,0.2,0.2,0.8]),
						function(){
							setTimeout(function(){
								scrolling = false;
								lastScrollTop = document.documentElement.scrollTop ? document.documentElement.scrollTop : 0;
							}, 500);
							event.preventDefault();
						}
					);
					break;
				}
			}
		}
		lastScrollTop = document.documentElement.scrollTop ? document.documentElement.scrollTop : 0;
	});
}

$(document).ready(function(){
	//clear form inputs
	$('form input, form textarea')
	.focusout(function() {
		if($(this).val().length > 0 || $(this).text().length > 0){
			$(this).siblings('.clear').show();
		}else{
			$(this).siblings('.clear').hide();
		}
	})
	.blur(function() {
		if($(this).val().length > 0 || $(this).text().length > 0){
			$(this).siblings('.clear').show();
		}
	})
	.keyup(function(){
		if($(this).val().length > 0 || $(this).text().length > 0){
			$(this).siblings('.clear').show();
		}
	});
	$(document).on('click', 'form .clear', function(){
		$(this).siblings('input, textarea').val('').text('');
	});
	
	//check clip path support
	if($('html').hasClass('no-cssclippathpolygon')){
		$('.browser-support').addClass('active');
	}
	
	$(document).on('click', '.burger', function(){
		$('header').toggleClass('opened');
	});
	$('.header-slider-num .next-arrow').on('click', function(){
		$('.header-slider').slick('slickNext');
	});
	$('.header-slider-num .prev-arrow').on('click', function(){
		$('.header-slider').slick('slickPrev');
	});

	$(".header-slider").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		centerMode: false,
		autoplay: true,
		lazyLoad: 'ondemand',
		autoplaySpeed: 5000,
		infinite: false,
		draggable: true,
		vertical: true,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					dots: true
				}
			}
		]
	});
	$('.header-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		if( currentSlide != nextSlide ){
			var index = nextSlide;
			var item = $('.header-slider-num-wrapper .slider div');
			var currentItem = $('.header-slider-num-wrapper .slider div').eq(index);
			var box = $('.header-slider-num-wrapper');
			
			item.each(function(){
				$(this).removeClass('active');
			});
			box.animate({scrollTop: box.scrollTop() + (currentItem.offset().top - box.offset().top) - 30});
			currentItem.addClass('active');
		}
	});
	$('.header-slider').on('lazyLoaded', function( event, slick, image, imageSource ){
		$(image)
			.closest('picture')
			.find('source[data-lazy-srcset]')
			.each(function () {
				$(this).attr('srcset', $(this).data('lazy-srcset'));
			});
	});
	
	swipeMenu();
	
	$("#projects .list").slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		autoplay: false,
		infinite: true,
		draggable: true,
		focusOnSelect: false,
		variableWidth: true
	});
	$('#projects .next').on('click', function(){
		$('#projects .list').slick('slickNext');
	});
	$('#projects .prev').on('click', function(){
		$('#projects .list').slick('slickPrev');
	});
	
	$("#news .slider").slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		centerMode: true,
		centerPadding: 140,
		autoplay: false,
		autoplaySpeed: 5000,
		infinite: true,
		variableWidth: true,
		draggable: true,
		focusOnSelect: false
	});
	$('#news .slider-nav-container .next').on('click', function(){
		$('#news .slider').slick('slickNext');
	});
	$('#news .slider-nav-container .prev').on('click', function(){
		$('#news .slider').slick('slickPrev');
	});
	$('#news .slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		if( currentSlide != nextSlide ){
			var index = nextSlide;
			
			var item = $('#news .slider-nav div.item');
			var currentItem = $('#news .slider-nav div.item').eq(index);
			var box = $('#news .slider-nav-container .wrapper');
			
			item.each(function(){
				$(this).removeClass('active');
			});
			
			box.animate({scrollLeft: box.scrollLeft() + (currentItem.offset().left - box.offset().left) - 26});
			currentItem.addClass('active');
		}
	});
	
	//reviews slider
	$('#reviews .review-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: false,
		centerPadding: 0,
		dots: false,
		arrows: false,
		infinite: false,
		autoplay: false,
		draggable: true,
		focusOnSelect: false,
		vertical: false
	});
	$('.review-slider-nav .next').on('click', function(){
		$('#reviews .review-slider').slick('slickNext');
	});
	$('.review-slider-nav .prev').on('click', function(){
		$('#reviews .review-slider').slick('slickPrev');
	});
	$('#reviews .review-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		if( currentSlide != nextSlide ){
			var index = nextSlide;
			
			var item = $('.review-slider-nav .slider-nav-container div.item');
			var currentItem = $('.review-slider-nav .slider-nav-container div.item').eq(index);
			var box = $('.review-slider-nav .slider-nav-container .wrapper');
			
			item.each(function(){
				$(this).removeClass('active');
			});
			
			box.animate({scrollLeft: box.scrollLeft() + (currentItem.offset().left - box.offset().left) - 26});
			currentItem.addClass('active');
		}
	});
	
	//instagram
	$('#instagram .list').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: false,
		centerPadding: 0,
		dots: false,
		arrows: false,
		infinite: true,
		autoplay: false,
		draggable: true,
		focusOnSelect: false,
		vertical: false,
		variableWidth: true,
	});
	$('#instagram .next').on('click', function(){
		$('#instagram .list').slick('slickNext');
	});
	$('#instagram .prev').on('click', function(){
		$('#instagram .list').slick('slickPrev');
	});
	
	if($('footer #mapData').length > 0){
		setTimeout(function () {
			$.loadScript('https://api-maps.yandex.ru/2.1/?lang=ru&amp;apikey=c147bf32-eee3-4fa9-816a-5202ffa0210a&amp;load=package.standard', function(){
				ymaps.ready(function () {
					if(checkWindowWidth(1180)){
						var center = [59.941380, 30.319821];
					}else{
						var center = [59.941479, 30.316373];
					}
					var myMap = new ymaps.Map('mapData', {
							center: center,
							zoom: 17,
							controls: []
						}, {
							searchControlProvider: 'yandex#search'
						}),
						myPlacemark = new ymaps.Placemark([59.941380, 30.319821], {
							hintContent: 'Art Spice, Набережная реки Мойки, Дом 31Н',
							balloonContent: 'Art Spice',
							balloonContentHeader: "Art Spice",
							balloonContentBody: "Набережная реки Мойки, Дом 31Н"
						}, {
							// Опции.
							// Необходимо указать данный тип макета.
							preset: 'islands#redHomeCircleIcon',
						});
					//myMap.behaviors.disable('scrollZoom');
					//myMap.behaviors.disable('drag');
					myMap.geoObjects.add(myPlacemark);
				});
			});
		}, 3000);
	}
	
	$('.header-slider-num-wrapper .slider').html('');
	for (var i = 1; i <= $('.header-slider .slick-slide').length; i++) {
		if(i == 1){
			$('.header-slider-num-wrapper .slider').append('<div class="active">'+i+'</div>');
		}else{
			$('.header-slider-num-wrapper .slider').append('<div>'+i+'</div>');
		}
	}
	
	$("form[name=question]").validate({
		rules: {
			// simple rule, converted to {required:true}
			name: "required",
			// compound rule
			email: {
				required: true,
				email: true
			},
			phone: "required",
			message: "required"
		},
		messages: {
			name: 'Введите имя',
			email: 'Введите email',
			phone: 'Введите телефон',
			message: 'Введите текст сообщения'
		},
		errorPlacement: function(error, element) {

		},
		submitHandler: function(form) {
			//if success
			var data = $(form).serialize();
			var arr = $(form).serializeArray();
			
			$.ajax({
				url: '/ajax/form_submit.php',
				data: data,
				method: 'POST',
				cache: false,
				success: function(response)
				{
					clean_form( $("form[name=question]") );
					$.magnificPopup.open({
						items: {
							src: '/ajax/popup_success.php'
						},
						type: 'ajax',
						showCloseBtn: false
					}, 0);
				}
			});
		}
	});
	
	$("form[name=contacts]").validate({
		rules: {
			// simple rule, converted to {required:true}
			name: "required",
			// compound rule
			email: {
				required: true,
				email: true
			},
			phone: "required",
			message: "required"
		},
		messages: {
			name: 'Введите имя',
			email: 'Введите email',
			phone: 'Введите телефон',
			message: 'Введите текст сообщения'
		},
		errorPlacement: function(error, element) {
		
		},
		submitHandler: function(form) {
			//if success
			var data = $(form).serialize();
			var arr = $(form).serializeArray();
			
			$.ajax({
				url: '/ajax/form_submit.php',
				data: data,
				method: 'POST',
				cache: false,
				success: function(response)
				{
					clean_form( $("form[name=contacts]") );
					$.magnificPopup.open({
						items: {
							src: '/ajax/popup_success.php'
						},
						type: 'ajax',
						showCloseBtn: false
					}, 0);
				}
			});
		}
	});
	
	$(document).on('click', '.white-popup .close', function(){
		$.magnificPopup.close();
	});
});

$(window).resize(function(){
	swipeMenu();
	if(checkWindowWidth(767) == false && $('#media').length > 0){
		if(window['ps'] != null){
			window['ps'].update();
		}else{
			window['ps'] = new PerfectScrollbar('#media > .content .wrapper', {
				wheelSpeed: 2,
				wheelPropagation: true,
				minScrollbarLength: 20,
				maxScrollbarLength: 75,
				suppressScrollY: true,
			});
		}
	}else{
		if(window['ps'] != null){
			window['ps'].destroy();
			window['ps'] = null;
		}
	}
});

function swipeMenu(){
	if(checkWindowWidth(1320)){
		if($('.menu a').length > 0){
			$($('.menu a').get().reverse()).each(function(){
				$('.menu-extras').prepend($(this));
			});
		}
	}else{
		if($('.menu-extras a').length > 0 && $('.menu a').length == 0){
			var x = 0;
			$('.menu-extras a').each(function(){
				if(x == 3) return;
				$('.menu').append($(this));
				x++;
			});
		}
	}
}

$.loadScript = function (url, callback) {
	$.ajax({
		url: url,
		dataType: 'script',
		success: callback,
		async: true
	});
}

$(function() {
	var wow = new WOW(
		{
			boxClass: 'wow',      // animated element css class (default is wow)
			animateClass: 'animated', // animation css class (default is animated)
			offset: 50,          // distance to the element when triggering the animation (default is 0)
			mobile: false,       // trigger animations on mobile devices (default is true)
			live: true,       // act on asynchronously loaded content (default is true)
			callback: function (box) {
				//console.log(box);
			},
			scrollContainer: null,    // optional scroll container selector, otherwise use window,
			resetAnimation: true,     // reset animation on end (default is true)
		}
	);
	wow.init();
});