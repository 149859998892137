function checkWindowWidth(width){
	var windowWidth = $(window).outerWidth();
	if(windowWidth <= width){
		return true;
	}else{
		return false;
	}
}

function detectScrollbar() {
	var body = document.querySelector('body');
	
	if (window.innerWidth > body.clientWidth + 5) {
		body.classList.add('has-scrollbar');
		body.setAttribute('style', '--scroll-bar: ' + (window.innerWidth - body.clientWidth) + 'px');
	} else {
		body.classList.remove('has-scrollbar');
	}
}

function clean_form(form, clearCheckbox = false, errorBox = false) {
	$(':input', form)
		.not(':button, :submit, :reset, :hidden')
		.prop('selected', false)
		.val('');
	$('textarea', form)
		.text('');
	$('.clear').each(function(){
		$(this).hide();
	});
	return true;
}