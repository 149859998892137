$(document).ready(function(){
	/*
	$(".project-list .list").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		centerMode: false,
		autoplay: false,
		infinite: false,
		draggable: true,
		focusOnSelect: true
	});
	$('.project-list .list').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		if( currentSlide != nextSlide ){
			var index = nextSlide;
			
			var item = $('.slider-nav-container div.item');
			var currentItem = $('.slider-nav-container div.item').eq(index);
			var box = $('.slider-nav-container .wrapper');
			
			item.each(function(){
				$(this).removeClass('active');
			});
			
			box.animate({scrollLeft: box.scrollLeft() + (currentItem.offset().left - box.offset().left) - 26});
			currentItem.addClass('active');
		}
	});
	$('.project-list .next').on('click', function(){
		$('.project-list .list').slick('slickNext');
	});
	$('.project-list .prev').on('click', function(){
		$('.project-list .list').slick('slickPrev');
	});
	
	if($('.project-list .list').length > 0 && $('.temp').length > 0){
		sliceProjects();
	}
    */
	
	$(".picture-list .list").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		centerMode: false,
		autoplay: false,
		infinite: false,
		draggable: true,
		focusOnSelect: true,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					dots: true
				}
			},
		]
	});
	$('.picture-list .list').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		if( currentSlide != nextSlide ){
			var index = nextSlide;
			
			var item = $('.slider-nav-container div.item');
			var currentItem = $('.slider-nav-container div.item').eq(index);
			var box = $('.slider-nav-container .wrapper');
			
			item.each(function(){
				$(this).removeClass('active');
			});
			
			box.animate({scrollLeft: box.scrollLeft() + (currentItem.offset().left - box.offset().left) - 26});
			currentItem.addClass('active');
		}
	});
	$('.picture-list .next').on('click', function(){
		$('.picture-list .list').slick('slickNext');
	});
	$('.picture-list .prev').on('click', function(){
		$('.picture-list .list').slick('slickPrev');
	});
});

function sliceProjects(){
	$('.temp').html();
	
	$(".project-list .list a").each(function(){
		var cl = $(this).clone();
		var d = document.createElement('div');
		$(d).addClass('item');
		$(cl).appendTo( $(d) );
		
		$(d).appendTo('.temp');
	});
	
	$(".temp").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		arrows: false,
		centerMode: false,
		autoplay: false,
		infinite: false,
		draggable: true,
		focusOnSelect: true,
		adaptiveHeight: true
	});
}